.andrii-table-header--item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.andrii-table-body--1item {
  text-align: left;
  margin-left: 10px;
}

.andrii-table-box-header {
  background-color: #016193;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  border: 0px solid #016193;
}
.andrii-table-box-header--title {
  color: #f8f1e7;
  margin-bottom: 0px;
}
.andrii-table-box-header--text {
  color: #f8f1e7;
}

.andrii-table-header {
  font-weight: bold;
  background: #6e9ab9;
  color: #f8f1e7;
  display: grid;
  text-align: center;
  font-size: 13px;
  min-height: 38px;
}
.andrii-table-total {
  font-weight: bold;
}

.andrii-table-br {
  border-right: 1px solid #00486e6b;
}
.andrii-table-bt {
  border-top: 1px solid #00486e6b;
}
.andrii-table-bb {
  border-bottom: 1px solid #00486e6b;
}

.andrii-table-box {
  border-radius: 5px;
  /* border: 1px solid black;  */
  background-color: #f8f1e7;
}
.andrii-table-box--body {
  /* padding: 10px; */
  padding-bottom: 15px;
}
.andrii-table {
  /* border: 1px solid black; */
}
.andrii-table-body {
  display: grid;
  text-align: center;
  font-size: 13px;
}
.andrii-table-box--radio-input input[type="radio"] {
  display: none;
}

.andrii-table-box--radio-input label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 20px;
  /* margin-right: 0; */
  line-height: 18px;
  user-select: none;
  margin-right: 20px;
}
.andrii-table-box--radio-input label:before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  bottom: 1px;
  /* background: #6e9ab9; */
  background: #97a8b4;
  border-radius: 50%;
  border: 2.5px solid rgb(255, 255, 255);
}

/* Checked */
.andrii-table-box--radio-input input[type="radio"]:checked + label:before {
  background: #016193;
  /* border: 2px solid rgb(240, 50, 50); */
  border-radius: 50%;
}

/* Hover */
.andrii-table-box--radio-input label:hover:before {
  filter: brightness(120%);
}

/* Disabled */
.andrii-table-box--radio-input input[type="radio"]:disabled + label:before {
  filter: grayscale(100%);
}

.andrii-table-amount {
  text-align: right;
  padding-right: 3px;
  overflow-x: hidden;
}
.andrii-table-count {
  text-align: right;
  padding-right: 6px;
  overflow-x: hidden;
}
