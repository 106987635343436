.eas-taxes-end-sales--main {
  margin: 0;
  /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin-bottom: 100px;

  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(610px, 1fr));
  grid-template-rows: repeat(4, auto-fit);
  grid-gap: 1rem;
  grid-auto-flow: row dense;
}
